import React from "react"

const PrivacyPage = () => {
  return (
    <>
      <div className="win-host py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12 header-info">
              <h1>Privacy</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12">
            <h2>Privacy Policy</h2>
            <p>
              <strong>Privacy Policy:</strong> This Privacy Policy governs the
              manner in which Mothersoft Technologies collects, uses, maintains
              and discloses information collected from users of our web site and
              hosting service.
            </p>
            <p>
              <strong>Privacy:</strong> Users' privacy is very important to
              Mothersoft Technologies Mothersoft Technologies is committed to
              safeguarding the information Users entrust to Mothersoft
              Technologies This Web site is not directed at children under 13
              years of age.
            </p>
            <p>
              <strong>The Information We Collect:</strong> Mothersoft
              Technologies may collect personally identifiable information from
              Users in a variety of ways, including through online forms for
              ordering products and services, and other instances where Users
              are invited to volunteer such information. Mothersoft Technologies
              may also collect information about how Users use our Web site, for
              example, by tracking the number of unique views received by the
              pages of the Web site or the domains from which Users originate.
              We may use "cookies" to track how Users use our Web site. A cookie
              is a piece of software that a Web server can store on the Users PC
              and use to identify the User should they visit the Web site again.
              While not all of the information that we collect from Users is
              personally identifiable, it may be associated with personally
              identifiable information that Users provide us through our Web
              site.
            </p>
            <p>
              <strong>How We Use Information:</strong> Mothersoft Technologies
              may use personally identifiable information collected through our
              Web site to contact Users regarding products and services offered
              by Mothersoft Technologies and its trusted affiliates, independent
              contractors and business partners, and otherwise to enhance Users'
              experience with Mothersoft Technologies and such affiliates,
              independent contractors and business partners. Mothersoft
              Technologies may also use information collected through our Web
              site for research regarding the effectiveness of the Web site and
              the marketing, advertising and sales efforts of Mothersoft
              Technologies, its trusted affiliates, independent contractors and
              business partners.
            </p>
            <p>
              <strong>Disclosure of Information:</strong> Mothersoft
              Technologies may disclose information collected from Users to
              trusted affiliates, independent contractors and business partners
              who will use the information for the purposes outlined above. We
              may also disclose aggregate, anonymous data based on information
              collected from Users to investors and potential partners. Finally,
              we may transfer information collected from Users in connection
              with a sale of Mothersoft Technologies business.
            </p>
            <p>
              <strong>Maintenance of Information:</strong> Information about
              Users that is maintained on our systems is protected using
              industry standard security measures. However, we cannot guarantee
              that the information submitted to, maintained on, or transmitted
              from our systems will be completely secure.
            </p>
            <p>
              <strong>Questions:</strong> Users may direct questions concerning
              this Privacy Policy by email to{" "}
              <a href="mailto:support@mothersoft.in">support@mothersoft.in</a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPage
